// font families


@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RobotoMedium;
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: RobotoBold;
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: RobotoLight;
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: RokkittBold;
    src: url('./fonts/Rokkitt-Bold.ttf') format('truetype');
  }

  :root {
    --navbar-height: 80px;
    --theme-bg-color-mui: #050711;
    --primary-color-mui: #7A59E5;
    --secondary-color-mui: #F259FF;
    --footer-height: 189px;
  }

//mui overload
.MuiButton-root{
    text-transform: none!important;
    border-radius: 8px!important;
}

.btn-bold{
    font-weight: 600!important;
}

.MuiButton-root:focus, .MuiTab-root:focus {
    outline: none!important;
}


.text-secondary {
  color: var(--gray-white)
}

//flexbox
.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .flex-start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
   }

.flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }

 .flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
 }

 .flex-start-between{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
 }

 .flex-column-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column!important;
 }

 .flex-column-start{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
 }

 .flex-justify-center{
  display: flex;
  align-items: flex-start;
  justify-content: center;
 }


 //common
.social-icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.arrow-down-img{
  // filter: invert(45%) sepia(39%) saturate(2685%) hue-rotate(256deg) brightness(103%) contrast(102%);
  filter: invert(60%) sepia(100%) saturate(3742%) hue-rotate(255deg) brightness(99%) contrast(104%);
  width:30px;
  height: auto;
}

//common input
.dark-input{
  background-color: black;
  // padding: 15px 20px;
  border-radius: 10px;
  outline: none;
  caret-color: white;
  border: none;
  margin-top: 5px;
  min-height: 30px;
  color: #f0f0f0;
  font-size: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  width: 100%;
  max-width: 100%;

  padding: 10px 20px;

  &.error{
    border: 1px solid #f44336;
  }

  &.small{
      max-width: 250px;
  }

  &::-webkit-input-placeholder { /* Edge */
    color: #a9a9a9;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    // color: #C4C4C4;
    color: #a9a9a9;

  }
  
  &::placeholder {
    // color: #C4C4C4;
    color: #a9a9a9;

  }
}

//containers
.dark-container{
  width: 100%;
  background-color: black;
  padding: 20px 20px;
  border-radius: 10px;
  margin-top: 5px;
  min-height: 30px;
  padding: 15px 20px;

  &.error{
    border: 1px solid #f44336;
  }
}


//font
.roboto-medium{
  font-family: RobotoMedium!important;
}

//others
.full-height{
 height: calc(100vh - var(--navbar-height));
 min-height: 250px;

 &--abs-nav{
  height: 100vh;
  min-height: 250px;
 }
}

.scroll-margin{
  scroll-margin-top: 30px;
}

.bg-section-theme{
  background: var(--bg-section);
}




//scrollbar
// body::-webkit-scrollbar {
//   width: 8px;
// }
 
// body::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
 
// body::-webkit-scrollbar-thumb {
//   background-color: #413d42;
//   outline: 1px solid #413d42;
//   border-radius: 4px;
// }

@media (max-width: 768px) {
  :root {
    --navbar-height: 80px;
  }
}


   
