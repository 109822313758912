$notification_Height : 480px;

.notification__container {
  position: fixed;
  bottom: 30px;
  width: $notification_Height;
  // max-width: 600px;
  z-index: 999;
  min-height: 68px;
  padding: 5px 16px 5px 12px;
  background-color: #1f1f2c;
  -webkit-box-shadow: 11.811px 11.811px 59.0551px rgba(0, 0, 0, 0.5);
  box-shadow: 11.811px 11.811px 59.0551px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: all .4s;
      -ms-transform: all .4s;
          transform: all .4s;
  .sidebar{
    width: 4px;
    min-height: 50px;
    // height: auto;
    height: max-content !important;
    background: #ffbd0c;
    border-radius: 8px;
    @media screen and (max-width:576px) {
      // height: 50px !important;
      // height: $notification_Height !important;
      height: auto !important;
    }
  }

  .notification__box {
    border-right: 1px solid rgba(235, 235, 235, 0.07);
    width: 68%;
    h3 {
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      color: "#fff";
      @media screen and (max-width:576px) {
        font-size: 12px !important;
      }
    }

    h4 {
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ebebeb;
      @media screen and (max-width:576px) {
        font-size: 12px !important;
      }
    }
  }

  .notification__btn {
    span {
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #5142fc;
      cursor: pointer;
      @media screen and (max-width:576px) {
        font-size: 13px !important;
      }
    }
  }

  @media screen and (max-width:576px) {
    // width: 90% !important;
    // height: fit-content !important;
    $notification_Height : 90%;
    width: $notification_Height !important;
  }

  .closeicons{
    margin-left: 10px;
    color: #8A8AA0;
  }
}
